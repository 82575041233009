@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@600&display=swap");

@layer base {
  body {
    @apply font-[Roboto];
  }
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
  .section {
    @apply py-12 lg:py-32;
  }
  .btn-lg {
    @apply h-[54px];
  }
  .btn-md {
    @apply h-[48px];
  }
}
.map-container {
  height: 700px;
  z-index: -1;
}
.text-4xl {
  position: absolute;
  top: 0%;
  left: 50%;
}
.teachers-heading {
  text-align: center;
  margin: 2rem 0;
}

@media (max-width: 768px) {
  .teachers-heading {
    margin-top: 2rem;
  }
}

/* Egyedi stílusok a görgetősávhoz */
.scrollbar::-webkit-scrollbar {
  width: 8px;
}

.scrollbar::-webkit-scrollbar-track {
  background: #2a2a2a; /* Sötétebb háttér */
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #555; /* Szürke görgetősáv */
  border-radius: 10px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #777; /* Világosabb szín hover állapotban */
}
